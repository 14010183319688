import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider, createTheme, CssBaseline} from "@mui/material";
import './styles/index.css';
import App from './components/App';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {Provider} from "react-redux";
import store from "./redux/store";
import {fetchEvents} from "./redux/slices/eventsSlice";
import {fetchCategories} from "./redux/slices/categoriesSlice";

store.dispatch(fetchCategories());
store.dispatch(fetchEvents());

dayjs.extend(advancedFormat);

const darkTheme = createTheme({
palette: {
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <React.StrictMode>
          <CssBaseline/>
          <App/>
        </React.StrictMode>
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>
);

