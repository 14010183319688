import {Dayjs} from "dayjs";
import {Divider, Grid, Typography} from "@mui/material";
import EventOverviewItemOutlet from "./EventOverviewItemOutlet";
import useInfiniteScroll, {ScrollDirection, ScrollParams} from "react-easy-infinite-scroll-hook";
import {useState} from "react";
import {useSelector} from "react-redux";
import {selectEvents} from "../redux/slices/eventsSlice";
import {getEventsOverlappingEvent, getEventsOverlappingTimeframe} from "../util/eventOverviewTiling";
import type Event from "../types/event";
import "../styles/EventOverview.css";

export const HEIGHT_UNIT = "rem";
export const HEIGHT_PER_HOUR = 4;
export const TOTAL_HEIGHT_EVENT_OUTLET = `${HEIGHT_PER_HOUR * 24}${HEIGHT_UNIT}`;

const HOURS_PER_JUMP = 24 * 3;

export type CurrentTimeCallback = (time: Dayjs) => void;

function EventOverviewInfiniteScrolling(props: { startingDay: Dayjs, updateCurrentTime: CurrentTimeCallback }) {
  const {startingDay, updateCurrentTime} = props;
  
  const events = useSelector(selectEvents);
  
  const [previousStart, setPreviousStart] = useState(startingDay.valueOf());
  
  const [hours, setHours] = useState([] as Array<number>);
  const [scrollToTop, setScrollToTop] = useState(null as number | null);
  
  // Stores a time till when the displayed date should not be updated.
  // This is to avoid flickering when inserting new elements for infinite scrolling.
  const [timeChangeLock, setTimeChangeLock] = useState(0);
  
  const firstDisplayedTime = hours.length === 0 ? startingDay : startingDay.add((hours.at(0) as number), "hours");
  const lastDisplayedTime = hours.length === 0 ? startingDay : startingDay.add((hours.at(-1) as number), "hours");
  
  const next = async (direction: ScrollDirection) => {
    let limitHour: number | undefined = hours.at(direction === ScrollDirection.UP ? 0 : -1);
    if (limitHour === undefined) {
      limitHour = 0;
    }
    let newHours;
    
    if (hours.length > 0) { // Add more hours to an already populated list
      newHours = Array.from({length: HOURS_PER_JUMP},
        // @ts-ignored
        (_, i) => ((i + 1) * (direction === ScrollDirection.UP ? -1 : 1) + limitHour)
      );
      if (direction === ScrollDirection.UP) {
        newHours = [...newHours.reverse(), ...hours];
      } else {
        newHours = [...hours, ...newHours];
      }
    } else { // Create a new list form scratch with values around zero
      newHours = Array.from({length: HOURS_PER_JUMP},
        // (- HOURS_PER_JUMP + 12) will position the screen at the center of the selected day
        (_, i) => (i + 1 - HOURS_PER_JUMP + 12)
      );
    }
    // Stop updating the displayed date for a short while to avoid flickering.
    setTimeChangeLock(Date.now() + 1000);
    setHours(newHours);
  }
  
  const convertRemToPixels = (rem: number) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
  
  const loadEvents = (newHours: number[]) => {
    let start = startingDay.add(newHours.at(0) as number, "hours");
    let end = startingDay.add(newHours.at(-1) as number, "hours");
    
    const relevantEvents = getEventsOverlappingTimeframe(events, start, end);
    
    if (relevantEvents.length === 0) {
      return [];
    }
    
    // Grouping all events, that overlap with each-other together for tiling.
    let eventCluster = [] as Array<Array<Event>>;
    let currentCluster = 0;
    relevantEvents.sort((a, b) => a.start.unix() - b.start.unix()).map(event => {
      if (eventCluster.length <= currentCluster) {
        eventCluster.push([]);
      }
      for (let i = 0; i < currentCluster; i++) {
        if (eventCluster[i].includes(event)) {
          currentCluster = i;
        }
      }
      const overlaps = getEventsOverlappingEvent(relevantEvents, event);
      eventCluster[currentCluster].push(...overlaps.filter(event => !eventCluster[currentCluster].includes(event)));
    });
    eventCluster = eventCluster.filter(cluster => cluster.length > 0);
    
    const firstEventStart = relevantEvents.sort((a, b) => a.start.unix() - b.start.unix())[0].start;
    const lestEventEnd = relevantEvents.sort((a, b) => b.end.unix() - a.end.unix())[0].end;
    
    // Filling up hours for the length of loaded events
    // @ts-ignore
    
    let hoursAddedAtTop = 0;
    while (firstEventStart.isBefore(start)) {
      firstEventStart.add(1, "hour");
      // @ts-ignore
      hours.unshift(hours.at(0) - 1);
      start = startingDay.add(newHours.at(0) as number, "hours");
      hoursAddedAtTop++;
    }
    
    while (lestEventEnd.isAfter(end)) {
      lestEventEnd.add(-1, "hour");
      // @ts-ignore
      hours.push(hours.at(-1) + 1);
      end = startingDay.add(newHours.at(-1) as number, "hours");
      hoursAddedAtTop++;
    }
    
    if(hoursAddedAtTop > 0) {
      setScrollToTop(convertRemToPixels((hoursAddedAtTop + 52) * HEIGHT_PER_HOUR));
    }
    
    return eventCluster;
  }
  
  const onScroll = (scroll: ScrollParams) => {
    if (!scroll.scrollTop || !scroll.scrollHeight || !scroll.clientHeight) return;
    const positionFraction = (scroll.scrollTop + scroll.clientHeight / 2) / scroll.scrollHeight;
    const positionSeconds = firstDisplayedTime.diff(lastDisplayedTime, "seconds") * positionFraction;
    const time = firstDisplayedTime.add(-positionSeconds, "seconds");
    // This is done to avoid flickering when inserting new infinite scroll elements.
    if (Date.now() < timeChangeLock) {
      return;
    }
    updateCurrentTime(time);
  }
  
  const reset = () => {
    setHours([]);
  }
  
  // Reset the hours when the starting day changes
  if (previousStart.valueOf() !== startingDay.valueOf()) {
    reset();
    setPreviousStart(startingDay.valueOf());
  }
  
  // Load the first set of hours to kickstart infinite scrolling
  if (hours.length === 0) {
    next(ScrollDirection.DOWN);
  }
  
  const scrollRef = useInfiniteScroll({
    next,
    onScroll,
    rowCount: hours.length,
    hasMore: {up: true, down: true},
  })
  
  // Should be computed on each render.
  // It requires little performance and prevents sync issues.
  const eventCluster = loadEvents(hours);
  
  if (scrollToTop !== null) {
    // @ts-ignore
    scrollRef.current?.scrollTo(0, scrollToTop);
    setScrollToTop(null);
  }
  
  return (
    // @ts-ignore
    <div ref={scrollRef} className={"event-overview-infinite-scrolling-box"}>
      
      {eventCluster.map(cluster => {
        const fistEventTime = cluster[0].start;
        const offset = fistEventTime.diff(firstDisplayedTime, "seconds") / 60 / 60 * HEIGHT_PER_HOUR;
        return (
          <EventOverviewItemOutlet key={eventCluster.indexOf(cluster)} events={cluster}
                                   yOffset={`${offset + 0.8}${HEIGHT_UNIT}`}></EventOverviewItemOutlet>
        );
      })}
      {hours.map((hour) => {
        const time = startingDay.add(hour, "hours");
        const displayDay = time.hour() === 0;
        const displayText = time.format(displayDay ? "Do MMM" : "HH:mm");
        return (
          <div className={"event-overview-time-divider"} key={hour} id={'' + hour} style={{
            top: `${HEIGHT_PER_HOUR * hours.indexOf(hour)}${HEIGHT_UNIT}`,
          }}>
            <Grid>
              <Divider variant={"fullWidth"} textAlign={"left"}
                       className={"divider-text-far-left " + (displayDay ? "day" : "")}><Typography
                align={"right"} fontWeight={displayDay ? 800 : 500}
                color={"GrayText"}>{displayText}</Typography></Divider>
            </Grid>
          </div>
        );
      })}
    </div>
  );
}

export default EventOverviewInfiniteScrolling;
