import React from 'react';
import Calendar from "./Calendar";
import "../styles/App.css";

function App() {
  return (
    <div id="app">
      <Calendar/>
    </div>
  );
}

export default App;
