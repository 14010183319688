import  Event from "../types/event";
import {Dayjs} from "dayjs";

export interface TilingResult {
  maximumOverlaps: number,
  width: number,
  xOffset: number,
  height: number,
  yOffset: number
}

export interface TilingResultMap {
  [key: number]: TilingResult
}

// Calculates the x position and width for events to display in EventOverview.
// The algorithm is optimized for maximum width of events without overlapping.
// Results are returned as fractions of 1.
export const getTilingMap = (events: Event[]) => {
  if (events.length === 0) {
    return {};
  }
  
  const results: TilingResultMap = {};
  const sortedByStart = events.sort((a, b) => a.start.unix() - b.start.unix());
  
  sortedByStart.map(event => {
    results[event.id] = {
      maximumOverlaps: 0,
      width: 0,
      xOffset: 0,
      height: 0,
      yOffset: 0
    }
  });
  
  // Calculate height and y offset
  const first = sortedByStart.at(0);
  const last = sortedByStart.at(-1);
  // @ts-ignore
  const totalTime = last.end.unix() - first.start.unix();
  sortedByStart.map(event => {
    const duration = event.end.unix() - event.start.unix();
    // @ts-ignore
    const timeBefore = event.start.unix() - first.start.unix();
    results[event.id].height = duration / totalTime;
    results[event.id].yOffset = timeBefore / totalTime;
  });
  
  // This is the maximum number of events the overlap at any time
  let numberOfColumns = 1;
  
  // Count maximum simultaneous overlaps for each event and overall
  sortedByStart.map(event => {
    const overlapsAtStart = getEventsOverlappingTime(events, event.start);
    const count = overlapsAtStart.length;
    if (count > numberOfColumns) {
      numberOfColumns = count;
    }
    overlapsAtStart.map(event => {
      if (results[event.id].maximumOverlaps < count) {
        results[event.id].maximumOverlaps = count;
      }
    });
  });
  
  // Calculate the width of each event
  events.map(event => results[event.id].width = (numberOfColumns - results[event.id].maximumOverlaps + 1) / numberOfColumns);
  
  // Increase the offset of each element, till none overlap.
  for (let i = 0; i < events.length; i++) {
    const event = sortedByStart[i];
    const result = results[event.id];
    let blocking = getEventsOverlappingEvent(sortedByStart, event);
    blocking = blocking.filter(blockingEvent => sortedByStart.indexOf(blockingEvent) < i);
    let repeat = true;
    while (blocking.length > 0 && repeat) {
      repeat = false;
      blocking.map(blockingEvent => {
        const blockingResult = results[blockingEvent.id];
        if (blockingResult.xOffset + blockingResult.width <= result.xOffset) {
          return;
        }
        if (blockingResult.xOffset >= result.xOffset + result.width) {
          return;
        }
        result.xOffset += (1 / numberOfColumns);
        repeat = true;
      });
    }
  }
  
  return results;
}

export const getEventsOverlappingTime = (events: Event[], time: Dayjs) => {
  return events.filter(event => eventIsOverlappingTime(event, time));
}

// Returns true, if event.start <= time < event.end!
// This allows events to overlap if one ends and the other begins at the same time.
export const eventIsOverlappingTime = (event: Event, time: Dayjs) => {
  return !time.isBefore(event.start) && time.isBefore(event.end);
}

export const getEventsOverlappingEvent = (events: Event[], event: Event) => {
  return events.filter((e) => eventIsOverlappingEvent(e, event));
}

export const eventIsOverlappingEvent = (a: Event, b: Event) => {
  return eventIsOverlappingTime(a, b.start) || eventIsOverlappingTime(b, a.start);
};

export const getEventsOverlappingTimeframe = (events: Event[], a: Dayjs, b: Dayjs) => {
  return events.filter(event => eventIsOverlappingTimeframe(event, a, b));
}

export const eventIsOverlappingTimeframe = (event: Event, a: Dayjs, b: Dayjs) => {
  return !event.end.isBefore(a) && !event.start.isAfter(b);
};

