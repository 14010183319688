const AVAILABLE_COLORS = [
  "#e51c23",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#5677fc",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#259b24",
  "#8bc34a",
  "#afb42b",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b"];

// Picks a random color from the list of available colors, based on another string.
// This is used to determine the color of a category based on its name.
export default function pickRandomColor(seed: string): string {
  const seedNumber = seed.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return AVAILABLE_COLORS[seedNumber % AVAILABLE_COLORS.length];
}
