import {
  Button,
  Container,
  FormControlLabel, IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Slide,
  Switch,
  TextField
} from "@mui/material";

import '../styles/EventEdit.css';
import {DatePicker, DateTimePicker, MobileDatePicker, MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import React, {useState} from "react";
import {
  AccountCircle,
  CloseRounded,
  DeleteRounded,
  LocationCityRounded,
  LocationOnRounded,
  SaveRounded
} from "@mui/icons-material";
import ImageSelector from "./ImageSelector";

import Event from "../types/event";
import Category from "../types/category";
import {setEvent, selectEventById, deleteEvent} from "../redux/slices/eventsSlice";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {selectSelectedEvent, setSelectedEventId} from "../redux/slices/eventOverviewSlice";

function EventEdit(props: { shown: boolean; setShown: (value: boolean) => void; time: dayjs.Dayjs }) {
  const dispatch = useAppDispatch();
  
  const selectedEvent = (useAppSelector(selectSelectedEvent) || {}) as Event;
  
  const {shown, setShown, time} = props;
  const [lastEventId, setLastEventId] = useState(-1);
  
  const [title, setTitle] = useState("" as string);
  const [location, setLocation] = useState("" as string);
  const [organizer, setOrganizer] = useState("" as string);
  const [status, setStatus] = useState("Free" as string);
  const [allDay, setAllDay] = useState(false);
  const [start, setStart] = useState(time.startOf("day").add(9, "hours") as dayjs.Dayjs | null);
  const [end, setEnd] = useState(time.startOf("day").add(10, "hours") as dayjs.Dayjs | null);
  const [webpage, setWebpage] = useState("" as string);
  const [imagedata, setImagedata] = useState("" as string | undefined);
  const [categories, setCategories] = useState([] as Category[]);
  
  if(Object.values(selectedEvent).length > 0 && selectedEvent.id !== lastEventId) {
    setTitle(selectedEvent.title || "");
    setLocation(selectedEvent.location || "");
    setOrganizer(selectedEvent.organizer || "");
    setStatus(selectedEvent.status || "Free");
    setAllDay(selectedEvent.allday || false);
    setStart(selectedEvent.start || time.startOf("day").add(9, "hours"));
    setEnd(selectedEvent.end || time.startOf("day").add(10, "hours"));
    setWebpage(selectedEvent.webpage || "");
    setImagedata(selectedEvent.imagedata || "");
    setCategories(selectedEvent.categories || []);
    
    setLastEventId(selectedEvent.id);
    setShown(true);
  }
  if(Object.values(selectedEvent).length === 0 && lastEventId !== -1) {
    setLastEventId(-1);
  }
  
  const closeEdit = () => {
    setShown(false);
    dispatch(setSelectedEventId(-1));
  }
  
  const setAllDayStartEnd = (date: dayjs.Dayjs | null) => {
    if (date === null) {
      setStart(null);
      setEnd(null);
      return;
    }
    setStart(date.startOf("day"));
    setEnd(date.endOf("day"));
  };
  
  const validateRegEx = (value: string, regEx: string) => {
    try {
      return new RegExp(regEx).test(value);
    } catch (e) {
      return false;
    }
  }
  
  const saveEvent = async () => {
    const newEvent = {} as Event;
    newEvent.title = title;
    newEvent.location = location;
    newEvent.organizer = organizer;
    newEvent.status = status as "Free" | "Busy" | "Tentative";
    newEvent.allday = allDay;
    newEvent.start = start as dayjs.Dayjs;
    newEvent.end = end as dayjs.Dayjs;
    newEvent.webpage = webpage;
    newEvent.imagedata = imagedata;
    newEvent.categories = categories;
    newEvent.id = selectedEvent.id;
    dispatch(setEvent(newEvent));
    closeEdit();
  }
  
  const removeEvent = () => {
    dispatch(deleteEvent(selectedEvent.id));
    closeEdit();
  }
  
  const validators = {
    title: () => title.length > 0 && title.length <= 50,
    start: () => start !== null,
    end: () => end !== null && (start === null || end.isAfter(start)),
    location: () => location.length <= 50,
    organizer: () => validateRegEx(organizer, "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), // Email regex
    webpage: () => webpage.length <= 100,
  };
  
  const validateEverything = () => {
    return Object.values(validators).every((validator) => validator());
  }
  
  return (
    <Container className={"event-edit-container" + (shown ? "" : " hidden")}>
      <IconButton onClick={() => closeEdit()} className={"event-edit-close-button"}
                  color={"inherit"}><CloseRounded/></IconButton>
      
      <Container className={"event-edit-form"}>
        <Container className={"event-edit-wrap-row"}>
          <TextField
            variant={"standard"}
            label="Title"
            error={!validators.title()}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          ></TextField>
          
          <TextField
            variant={"standard"}
            label="Organizer"
            error={!validators.organizer()}
            value={organizer}
            onChange={(event) => setOrganizer(event.target.value)}
          ></TextField>
        </Container>
        
        <TextField
          variant={"outlined"}
          label="Location"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <LocationOnRounded/>
              </InputAdornment>
            ),
          }}
          error={!validators.location()}
          value={location}
          onChange={(event) => setLocation(event.target.value)}
        ></TextField>
        
        <FormControlLabel value={allDay} control={<Switch checked={allDay} value={allDay} onChange={(_, value) => {
          setAllDay(value);
          if (value) {
            setAllDayStartEnd(start || dayjs());
          }
        }}/>} label="All Day"/>
        
        <Container className={"event-edit-date-select-outer-container" + (!allDay ? " large" : "")}>
          <Slide direction={"right"} in={!allDay}>
            <Container className={"event-edit-date-select-container"}>
              <MobileDateTimePicker label={"Start"} value={start} onChange={(value) => setStart(value)}/>
              <MobileDateTimePicker minDateTime={(start || dayjs()).add(1, "minutes")} label={"End"} value={end}
                                    onChange={(end) => setEnd(end)}/>
            </Container>
          </Slide>
          <Slide direction={"left"} in={allDay}>
            <Container className={"event-edit-date-select-container"}>
              <MobileDatePicker label={"Date"} value={start}
                                onChange={(value) => setAllDayStartEnd(value)}></MobileDatePicker>
            </Container>
          </Slide>
        </Container>
        
        <RadioGroup row value={status} onChange={(_, value) => setStatus(value)}>
          <FormControlLabel value="Free" control={<Radio color={"success"}/>} label="Free"/>
          <FormControlLabel value="Busy" control={<Radio color={"error"}/>} label="Busy"/>
          <FormControlLabel value="Tentative" control={<Radio color={"warning"}/>} label="Tentative"/>
        </RadioGroup>
        
        <ImageSelector imagedata={imagedata || selectedEvent.imageurl} setImagedata={setImagedata}/>
        
        <Container className={"event-edit-bottom-buttons"}>
          <Button disabled={!validateEverything()} onClick={() => saveEvent()} variant={"contained"}
                  endIcon={<SaveRounded/>}>Save</Button>
        </Container>
        {selectedEvent.id && <Button onClick={() => removeEvent()} color={"error"}>Delete Event</Button>}
      </Container>
    </Container>
  );
}

export default EventEdit;
