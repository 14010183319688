import type Event from "../types/event";
import EventOverviewItem from "./EventOverviewItem";
import {HEIGHT_PER_HOUR, HEIGHT_UNIT} from "./EventOverviewInfiniteScrolling";
import {getTilingMap} from "../util/eventOverviewTiling";
import pickRandomColor from "../util/pickRandomColor";

function EventOverviewItemOutlet(props: { events: Event[], yOffset: string }) {
  let {events, yOffset} = props;
  
  if (events.length == 0) {
    return <div></div>;
  }
  
  events = events.sort((a, b) => a.start.unix() - b.start.unix());
  // @ts-ignore
  const totalHeightOutlet = (events.at(-1).end.unix() - events.at(0).start.unix()) / 60 / 60 * HEIGHT_PER_HOUR;
  
  const tilingMap = getTilingMap(events);
  
  return (
    <div style={{
      position: "absolute",
      height: `${totalHeightOutlet}${HEIGHT_UNIT}`,
      width: "calc(100% - 60px - 6%)",
      top: yOffset,
      left: "calc(60px + 4%)",
      zIndex: 1000,
    }}>
      <div style={{
        position: "relative",
        height: "100%",
        width: "100%"
      }}>
        <style>
          {events.map(event => (`@scope{ #event-${event.id} {--event-color: ${pickRandomColor("" + event.id)}}\n`))}
        </style>
        {events.map(event => {
          return (
            <EventOverviewItem key={event.id} event={event} tile={tilingMap[event.id]}/>
          );
        })}
      </div>
    </div>
  );
}

export default EventOverviewItemOutlet;
