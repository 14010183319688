import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import Event from "../../types/event";
import type {API_EVENT} from "../../types/event";

import Category from "../../types/category";
import {selectCategoryById} from "./categoriesSlice";
import dayjs from "dayjs";
import deepCopy from "../../util/deepCopy";
import {API_URL} from "../../config";

interface EventsState {
  status: "loading" | "idle" | "failed",
  writeStatus: "idle" | "loading" | "failed",
  lastWrittenId: number,
  events: Event[]
}

const initialState: EventsState = {
  status: "idle",
  writeStatus: "idle",
  lastWrittenId: -1,
  events: []
};

const apiEventToEvent = (apiEvent: API_EVENT, state?: RootState) => {
  const event = deepCopy(apiEvent) as any;
  event.start = dayjs(event.start);
  event.end = dayjs(event.end);
  event.categories = event.categories.map((category: Category) => {
    // @ts-ignore
    return selectCategoryById(state, category.id);
  });
  return event as Event;
};

const eventToApiEvent = (event: Event) => {
  const apiEvent = deepCopy(event) as any;
  apiEvent.start = event.start.toISOString();
  apiEvent.end = event.end.toISOString();
  apiEvent.categories = event.categories.map((category: Category) => {
    return category.id;
  });
  if(apiEvent.imagedata === "") {
    delete apiEvent.imagedata;
  }
  return apiEvent as API_EVENT;
}

export const fetchEvents = createAsyncThunk<API_EVENT[]>('events/fetchEvents', async (_, store) => {
  const state = store.getState() as RootState;
  const response = await fetch(`${API_URL}/${state.user.id}/events`);
  return await response.json();
});

export const setEvent = createAsyncThunk<API_EVENT, Event>('events/createEvent', async (event, store) => {
  const apiEvent = eventToApiEvent(event);
  const state = store.getState() as RootState;
  const method = !!event.id ? "PUT" : "POST";
  const url = !!event.id ? `${API_URL}/${state.user.id}/events/${event.id}` : `${API_URL}/${state.user.id}/events`;
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiEvent),
  });
  return await response.json();
});

export const deleteEvent = createAsyncThunk<void, number>('events/deleteEvent', async (id, store) => {
  const state = store.getState() as RootState;
  const response = await fetch(`${API_URL}/${state.user.id}/events/${id}`, {
    method: "DELETE",
  });
  return await response.json();
});

export const selectEvents = (state: RootState) => {
  return state.events.events;
}

export const selectEventById = (state: RootState, id: number) => {
  return state.events.events.find(event => event.id === id);
}

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<Event[]>) => {
      state.events = action.payload;
    },
    addEvent: (state, action: PayloadAction<Event>) => {
      state.events.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteEvent.pending, (state, action) => {
      state.events = state.events.filter(event => event.id !== action.meta.arg);
    });
    builder.addCase(setEvent.fulfilled, (state, action) => {
      state.writeStatus = "idle";
      state.events = state.events.filter(event => event.id !== action.payload.id);
      state.lastWrittenId = action.payload.id;
      state.events.push(apiEventToEvent(action.payload));
    });
    builder.addCase(setEvent.pending, (state) => {
      console.log("loading");
      state.writeStatus = "loading";
    });
    builder.addCase(setEvent.rejected, (state) => {
      state.writeStatus = "failed";
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.status = "idle";
      state.events = action.payload.map(apiEvent => apiEventToEvent(apiEvent));
    });
    builder.addCase(fetchEvents.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchEvents.rejected, (state) => {
      state.status = "failed";
    });
  }
});

export const {setEvents, addEvent} = eventsSlice.actions;
export default eventsSlice.reducer;
