import Category from "../../types/category";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {API_URL} from "../../config";
import {RootState} from "../store";
import pickRandomColor from "../../util/pickRandomColor";

export type CategoriesState = {
  status: "idle" | "loading" | "failed",
  writeStatus: "idle" | "loading" | "failed",
  lastWrittenId: number,
  categories: Category[],
}

export const initialState: CategoriesState = {
  writeStatus: "idle",
  lastWrittenId: -1,
  status: "idle",
  categories: [],
};

export const fetchCategories = createAsyncThunk<Category[]>("categories/fetchCategories", async (_, store) => {
  const state = store.getState() as RootState;
  const response = await fetch(`${API_URL}/${state.user.id}/categories`);
  let categories = await response.json();
  categories = categories.map((category: Category) => {
    return {
      ...category,
      color: pickRandomColor(category.name),
    };
  });
  return categories;
});

export const selectCategoryById = (state: RootState, id: number) => {
  const matches = state.categories.categories.filter((c: Category) => c.id === id);
  if (matches.length > 0) {
    return matches[0];
  }
  return {
    id: id,
    name: "Unknown",
    color: "#F00"
  };
}

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.status = "idle";
      state.categories = action.payload;
    });
    builder.addCase(fetchCategories.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCategories.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {setCategories} = categoriesSlice.actions;
export const selectCategories = (state: RootState) => state.categories.categories;
export default categoriesSlice.reducer;
