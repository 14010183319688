import {combineReducers, configureStore} from "@reduxjs/toolkit";
import eventsReducer from "./slices/eventsSlice";
import userReducer from "./slices/userSlice";
import categoriesReducer from "./slices/categoriesSlice";
import eventOverviewReducer from "./slices/eventOverviewSlice"

const rootReducer = combineReducers({
    events: eventsReducer,
    user: userReducer,
    categories: categoriesReducer,
    eventOverview: eventOverviewReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export default store;
