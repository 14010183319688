import {USER_ID} from "../../config";
import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

export type UserState = {
  id: number,
}

const initialState: UserState = {
  id: USER_ID,
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
});

export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
