import '../styles/Calendar.css';
import {Container, Fade, Grid, Icon, IconButton, Slide, Zoom} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import EventOverviewInfiniteScrolling from "./EventOverviewInfiniteScrolling";
import React, {Dispatch, useState} from "react";
import {DateCalendar} from "@mui/x-date-pickers";
import {AddToHomeScreenRounded, ArrowBack, CalendarMonthRounded, EditCalendar} from "@mui/icons-material";
import EventEdit from "./EventEdit";

const REAL_DATE = dayjs().startOf("day");

function Calendar() {
  const [currentTime, setCurrentTime] = useState(REAL_DATE);
  const [eventViewStatingTime, setEventViewStartingTime] = useState(REAL_DATE);
  const [eventViewActive, setEventViewActive] = useState(false);
  const [eventEditActive, setEventEditActive] = useState(false);
  
  const updateCurrentTime = (time: dayjs.Dayjs) => {
    setCurrentTime(time)
  }
  
  return (
    <Grid container>
      <EventEdit time={currentTime} shown={eventEditActive} setShown={setEventEditActive}/>
      <div className={"calendar-navbar"}>
        <div className={"nav-element"}>
          <Slide direction={"right"} in={eventViewActive}>
            <IconButton onClick={() => setEventViewActive(false)}><ArrowBack/></IconButton>
          </Slide>
        </div>
        <div className={"nav-element"}>
          <span>{currentTime.format("Do MMMM YYYY")}</span>
        </div>
        <div className={"nav-element"}>
          <IconButton onClick={() => setEventEditActive(true)}><EditCalendar/></IconButton>
        </div>
      </div>
      <Slide className={"calendar-date-select-container"} direction={"right"}
             in={!eventViewActive}>
        <Container style={{height: "100%", width: "100%"}}>
          <DateCalendar value={currentTime} onChange={(value: Dayjs) => {
            const dateString = value.toISOString().split("T")[0] + "T00:00:00.000Z";
            const date = dayjs(dateString);
            setCurrentTime(date);
            setEventViewStartingTime(date);
            setEventViewActive(true);
          }}></DateCalendar>
        </Container>
      </Slide>
      <Slide style={{position: "absolute", top: "0"}} direction={"left"} in={eventViewActive}>
        <div style={{width: "100%"}}>
          <EventOverviewInfiniteScrolling updateCurrentTime={updateCurrentTime}
                                          startingDay={eventViewStatingTime}/>
        </div>
      </Slide>
    </Grid>
  );
}

export default Calendar;