import Event from "../types/event";
import {Grid, Typography} from "@mui/material";
import "../styles/EventOverviewItem.css";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedEvent, setSelectedEventId} from "../redux/slices/eventOverviewSlice";
import {TilingResult} from "../util/eventOverviewTiling";

function EventOverviewItem(props: { event: Event, tile: TilingResult }) {
  const {event, tile} = props;
  
  const dispatch = useDispatch();
  const selectedEvent = useSelector(selectSelectedEvent);
  const selected = selectedEvent !== undefined && selectedEvent.id === event.id;
  
  return (
    <div onClick={() => dispatch(setSelectedEventId(event.id))}
         className={"event-overview-item" + (selected ? " selected" : "")}
         id={`event-${event.id}`}
         style={{
           position: "absolute",
           left: `${tile.xOffset * 100}%`,
           top: `${tile.yOffset * 100}%`,
           width: `${tile.width * 100}%`,
           height: `${tile.height * 100}%`
         }}>
      <Grid container justifyContent={"left"} alignItems={"start"} className={"inner-container"}>
        <Grid item>
          <Typography className={"title"}>{event.title}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default EventOverviewItem;
