import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {selectEvents} from "./eventsSlice";

export interface EventOverview {
  selectedEvent?: number
}

const initialState: EventOverview = {
  selectedEvent: undefined,
}

export const eventOverviewSlice = createSlice({
  name: "eventOverview",
  initialState,
  reducers: {
    setSelectedEventId: (state, action: PayloadAction<number>) => {
      state.selectedEvent = action.payload;
    }
  }
});

export const selectSelectedEvent = (state: RootState) => {
  return selectEvents(state).find(event => event.id === state.eventOverview.selectedEvent);
}

export const {setSelectedEventId} = eventOverviewSlice.actions;
export default eventOverviewSlice.reducer;