import "../styles/ImageSelector.css"

import {Button, Collapse, Container, Grow, Typography} from "@mui/material";
import {useState} from "react";

const HIDDEN_UPLOAD_ID = "HIDDEN_IMAGE_UPLOAD";

function ImageSelector(props: { imagedata: string; setImagedata: (value: any) => void; }) {
  const {imagedata, setImagedata} = props;
  const [tooLarge, setTooLarge] = useState(false);
  
  const selectFile = () => {
    const fileInput = document.getElementById(HIDDEN_UPLOAD_ID);
    if (fileInput) {
      fileInput.click();
    }
  };
  
  return (
    <Container className={"image-selector"}>
      <input id={HIDDEN_UPLOAD_ID} type="file" accept="image/*" onChange={(event) => {
        const file = event.target.files?.item(0);
        if (!file) {
          return;
        }
        if (file.size > 500000) { // 500KB
          setTooLarge(true);
          return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
          setImagedata(event.target?.result);
        };
        reader.readAsDataURL(file as Blob);
      }}></input>
      <Collapse in={!!imagedata}>
        <img className={"image-display"} src={imagedata} alt={"Can not display image!"}></img>
      </Collapse>
      <Button onClick={() => selectFile()}>Select Image</Button>
      {tooLarge ? <Typography color={"error"}>The image is too large!</Typography> : null}
    </Container>
  );
}

export default ImageSelector;
